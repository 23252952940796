import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import { HomePage } from "./pages/HomePage";
import { MinnerStats } from "./pages/MinnerStats";

export const App = () => {
  return (
    <Router>
      <div className="App">
        <div className="app-container">
          <Header />
          <Routes>
            <Route path="/" element={<HomePage/>} />
            <Route path="/stats/:address" element={<MinnerStats/>} />
          </Routes>
          <Footer />
        </div>
      </div>
    </Router>
  );
}

