import './styles.css';

export const InfoBox = ({ value, label, icon }) => {
  return (
    <div className="info-box">
      <img src={icon} alt="Icon" className="icon-image" />
      <div className="data-value">{value}</div>
      <div className="data-label">{label}</div>
    </div>
  );
};
