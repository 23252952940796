//const API_BASE_URL = "http://144.76.75.94:50000/api/v1";
const API_BASE_URL = "https://eiyaro.maxfeehunter.com:50000/api/v1";

const fetchApi = async (endpoint, options = {}) => {
  try {
    const response = await fetch(`${API_BASE_URL}${endpoint}`, options);
    if (!response.ok) {
      throw new Error(`API call failed with status ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error("API call error:", error);
    throw error;
  }
};

export const fetchActiveMiners = () => {
  return fetchApi("/active-miners");
};

export const fetchTotalPoolHashrate = () => {
  return fetchApi("/stats/totalPoolHashrate");
};

export const fetchBlocksPerHour = (hours) => {
  return fetchApi(`/stats/blocks/${hours}`);
};

export const fetchBlocks = (currentPage = 1) => {
  return fetchApi(`/get-blocks?currentPage=${currentPage}`).then(
    (res) => res.data
  );
};

export const getHashrateHistory = () => {
  return fetchApi("/stats/poolHashrateHistory");
};

export const getMinerChart = (minerKey) => {
  return fetchApi(`/chart/${minerKey}`).catch((error) => {
    console.error("Error in fetch: ", error);
    throw error;
  });
};

export const getMinnerHashrate = (minnerAddress) => {
  return fetchApi(`/stats/miner/${minnerAddress}`).catch((error) => {
    console.error("Error in fetch: ", error);
    throw error;
  });
};

export const getMinnerLastSeen = (minnerAddress) => {
  return fetchApi(`/stats/lastshare/${minnerAddress}`).catch((error) => {
    console.error("Error in fetch: ", error);
    throw error;
  });
}

export const getMinnerPendingBalace = (minnerAddress) => {
  return fetchApi(`/pending-balance/${minnerAddress}`).catch((error) => {
    console.log(error);
    throw error;
  })
}

export const getMinnerLast24hoursPaid = (minnerAddress) => {
  return fetchApi(`/payments/last24hours/${minnerAddress}`).catch((error) => {
    console.log(error);
    throw error;
  })
}


export const getMinnerTotalPaid = (minnerAddress) => {
  return fetchApi(`/payments/total/${minnerAddress}`).catch((error) => {
    console.log(error);
    throw error;
  })
}

export const getMinnerBlocksFound = (minnerAddress) => {
  return fetchApi(`/stats/miner/blocks/${minnerAddress}`).catch((error) => {
    console.log(error);
    throw error;
  })
}

export const getWorkerData = (minnerAddress) => {
  return fetchApi(`/stats/workers/${minnerAddress}`).catch((error) => {
    console.log(error);
    throw error;
  })
}

export const fetchTransactions = (minnerAddress, currentPage = 1) => {
  return fetchApi(`/get-paid-transactions?minerAddress=${minnerAddress}&currentPage=${currentPage}`).then(
    (res) => res.data
  );
};




