import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import React, { useEffect, useMemo, useState, useCallback } from "react";
import { formatHashrate } from "../../utils/formatHashrate";
import { Line } from "react-chartjs-2";
import { useParams } from "react-router-dom";
import icon from "../../../src/icons/icon.png";
import { InfoBox } from "../../components/InfoBox";
import {
  getMinerChart,
  getMinnerBlocksFound,
  getMinnerHashrate,
  getMinnerLast24hoursPaid,
  getMinnerLastSeen,
  getMinnerPendingBalace,
  getMinnerTotalPaid,
  getWorkerData,
  fetchTransactions
} from "../../services/ApiService";
import "./styles.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
    },
    tooltip: {
      mode: "index",
      intersect: false,
      callbacks: {
        label: function (tooltipItem) {
          return `${tooltipItem.dataset.label}: ${formatHashrate(
            tooltipItem.raw
          )}`;
        },
      },
    },
  },
  interaction: {
    mode: "nearest",
    axis: "x",
    intersect: false,
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: true,
        color: "rgba(255, 255, 255, 0.1)",
      },
      ticks: {
        callback: function (value) {
          return formatHashrate(value);
        },
      },
    },
  },
};

export const MinnerStats = () => {
  const { address } = useParams();
  const [hashrateData, setHashrateData] = useState([]);
  const [minnerHashrate, setMinnerHashrate] = useState(null);
  const [minnerLastSeen, setMinnerLastSeen] = useState(null);
  const [minnerPendingBalance, setMinnerPendingBalance] = useState(null);
  const [minnerLast24hoursPaid, setMinnerLast24hoursPaid] = useState(null);
  const [minnerTotalPaid, setMinnerTotalPaid] = useState(null);
  const [minnerBlocksFound, setMinnerBlocksFound] = useState(null);
  const [workers, setWorkers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [transactions, setTransactions] = useState([]);


  const handleNextPage = useCallback(() => {
    setCurrentPage((prevState) => prevState + 1);
  }, []);

  const handlePrevPage = useCallback(() => {
    setCurrentPage((prevState) => (prevState - 1 < 1 ? 1 : prevState - 1));
  }, []);

  const handleFetchTransactions = useCallback(() => {
    fetchTransactions(address, currentPage).then((data) => {
      setTransactions(data);
    });
  }, [address, currentPage]);

  useEffect(() => {
    console.log("Address:", address);
    if (address) {
      getMinerChart(address)
        .then((data) => {
          if (data && Array.isArray(data)) {
            setHashrateData(data);
          } else {
            setHashrateData([]);
          }
        })
        .catch((error) => {
          console.error("Error fetching miner data:", error);
          setHashrateData([]);
        });

      getMinnerHashrate(address).then((data) => {
        setMinnerHashrate(data || null);
      });

      getMinnerLastSeen(address).then((data) => {
        setMinnerLastSeen(data);
      });

      getMinnerPendingBalace(address).then((data) => {
        setMinnerPendingBalance(data);
      });

      getMinnerLast24hoursPaid(address).then((data) => {
        setMinnerLast24hoursPaid(data);
      });

      getMinnerTotalPaid(address).then((data) => {
        setMinnerTotalPaid(data);
      });

      getMinnerBlocksFound(address).then((data) => {
        setMinnerBlocksFound(data);
      });

      handleFetchTransactions();

      getWorkerData(address).then((data) => {
        console.log(data);
        setWorkers(data.workerHashRates);
      });
    }
  }, [address, handleFetchTransactions]);

  const generateTimeLabels = (start, end, interval) => {
    const labels = [];
    const currentDate = new Date(start.getTime());

    while (currentDate <= end) {
      const minutes =
        Math.floor(currentDate.getMinutes() / interval) * interval;
      labels.push(
        `${currentDate.getDate()}/${
          currentDate.getMonth() + 1
        } ${currentDate.getHours()}:${minutes < 10 ? "0" : ""}${minutes}`
      );
      currentDate.setMinutes(currentDate.getMinutes() + interval);
    }

    return labels;
  };

  const labels = useMemo(() => {
    const endDate = new Date();
    const startDate = new Date(endDate.getTime() - 24 * 60 * 60 * 1000);
    return generateTimeLabels(startDate, endDate, 10);
  }, []);

  const sortedGroupedData = useMemo(
    () =>
      hashrateData.map((item) => ({
        x: `${new Date(item.time * 1000).toLocaleTimeString()}`,
        y: item.hashrate,
      })),
    [hashrateData]
  );

  const chartData = {
    labels,
    datasets: [
      {
        label: "Hashrate",
        data: sortedGroupedData.map((item) => item.y),
        borderColor: "rgba(159, 211, 252, 1)",
        backgroundColor: "rgba(208, 129, 247, 0.5)",
        fill: false,
        tension: 0.4,
      },
    ],
  };

  return (
    <div className="minner-stats-warpper">
      <div className="header">
        <h1>MINER STATISTICS</h1>
        <button className="--common-btn">Start Mining</button>
      </div>

      <div className="info-box-wrapper">
        <InfoBox
          icon={icon}
          label="Current Hashrate"
          value={formatHashrate(minnerHashrate?.hashRate[0]?.hashRate || 0)}
        />
        <InfoBox
          icon={icon}
          label="Average Hashrate 6h"
          value={formatHashrate(minnerHashrate?.hashRate[2]?.hashRate || 0)}
        />
        <InfoBox
          icon={icon}
          label="Average Hashrate 24h"
          value={formatHashrate(minnerHashrate?.hashRate[3]?.hashRate || 0)}
        />

        <InfoBox
          icon={icon}
          label="Last seen"
          value={minnerLastSeen?.lastShareTime || "-"}
        />
      </div>
      <div className="info-box-wrapper">
        <InfoBox
          icon={icon}
          label="Pending balance"
          value={`${minnerPendingBalance?.pendingBalance.toFixed(2) || 0} EY`}
        />
        <InfoBox
          icon={icon}
          label="Last 24h paid"
          value={`${`${minnerLast24hoursPaid?.totalPaidLast24H || 0} EY`}`}
        />
        <InfoBox
          icon={icon}
          label="Total Paid"
          value={`${minnerTotalPaid?.totalPaid || 0} EY`}
        />
        <InfoBox
          icon={icon}
          label="Blocks found"
          value={minnerBlocksFound?.blocksMined || 0}
        />
      </div>
      <div className="chart-area">
        <div className="chart-title">Miner Chart</div>
        <Line data={chartData} options={options} />
      </div>
      <div className="blocks-table">
        <div className="table-section">
          <div className="cell workers">Workers</div>
          <div className="cell workers">10 min Hashrate</div>
          <div className="cell workers">1 hour Hashrate</div>
          <div className="cell workers">6 hour Hashrate</div>
          <div className="cell workers">24 hour Hashrate</div>
        </div>
        {Object.entries(workers).map(([workerName, hashRates], index) => (
          <div className="table-section" key={index}>
            <div className="cell rewards">{workerName}</div>
            <div className="cell rewards">
              {formatHashrate(hashRates["10 minutes"] || 0)}
            </div>
            <div className="cell rewards">
              {formatHashrate(hashRates["1 hours"] || 0)}
            </div>
            <div className="cell rewards">
              {formatHashrate(hashRates["6 hours"] || 0)}
            </div>
            <div className="cell rewards">
              {formatHashrate(hashRates["24 hours"] || 0)}
            </div>
          </div>
        ))}
      </div>
      <div className="blocks-table">
      <div className="chart-title">Payment History</div>
      <hr className="under-line" />
        <div className="table-section">
          <div className="cell workers">Paid Date</div>
          <div className="cell workers">Height</div>
          <div className="cell workers">TxID Hash</div>
          <div className="cell workers"></div>
          <div className="cell workers">Amount</div>
        </div>
        {transactions.map((transaction, index) => (
          <div className="table-section" key={index}>
            <div className="cell rewards">{transaction.timestamp}</div>
            <div className="cell rewards">{transaction.block}</div>
            <div className="cell rewards">{transaction.txid}</div>
            <div className="cell rewards"></div>
            <div className="cell rewards">{typeof transaction.reward === 'number' ? transaction.reward.toFixed(2) : parseFloat(transaction.reward).toFixed(2)}</div>
          </div>
        ))}
        <div className="pagination-buttons">
          <button className="--common-btn" onClick={handlePrevPage}>
            Prev
          </button>
          <div className="current-page"> {currentPage}</div>
          <button className="--common-btn" onClick={handleNextPage}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
};
