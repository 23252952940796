import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Line } from "react-chartjs-2";
import { formatHashrate } from '../utils/formatHashrate';
import Modal from '../utils/Modal';
import icon from "../../src/icons/icon.png";
import { InfoBox } from "../components/InfoBox";
import {
  fetchActiveMiners,
  fetchBlocks,
  fetchBlocksPerHour,
  fetchTotalPoolHashrate,
  getHashrateHistory,
} from "../services/ApiService";
import "./HomePage.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
    },
    tooltip: {
      mode: "index",
      intersect: false,
      callbacks: {
        label: function(context) {
          let label = context.dataset.label || '';
          if (label) {
            label += ': ';
          }
          if (context.parsed.y !== null) {
            label += formatHashrate(context.parsed.y);
          }
          return label;
        }
      }
    },
  },
  interaction: {
    mode: "nearest",
    axis: "x",
    intersect: false,
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      ticks: {
        callback: function(value) {
          return formatHashrate(value);
        }
      },
      grid: {
        display: true,
        color: "rgba(255, 255, 255, 0.1)",
      },
    },
  },
};

export const HomePage = () => {
  const [hashrateData, setHashrateData] = useState([]);
  const [activeMiners, setActiveMiners] = useState(0);
  const [poolHashrate, setPoolHashrate] = useState("");
  const [blocksPerHour, setBlocksPerHour] = useState(0);
  const [blocks, setBlocks] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [copySuccess, setCopySuccess] = useState('');

  const minerSettings = `@cd /d "%~dp0"
t-rex.exe -a tensority --url stratum+tcp://eiyaro.maxfeehunter.com:28000 -u EIYARO_ADDRESS.RIG_NAME
pause`;

const copyToClipboard = () => {
  navigator.clipboard.writeText(minerSettings).then(() => {
    setCopySuccess('Settings copied to clipboard!');
    setTimeout(() => setCopySuccess(''), 3000);
  }, (err) => {
    console.error('Could not copy text: ', err);
  });
};

  const handleNextPage = useCallback(() => {
    setCurrentPage((prevState) => prevState + 1);
  }, []);

  const handlePrevPage = useCallback(() => {
    setCurrentPage((prevState) => (prevState - 1 < 1 ? 1 : prevState - 1));
  }, []);

  const handleFetchBlocks = useCallback(() => {
    fetchBlocks(currentPage).then((data) => {
      setBlocks(data);
    });
  }, [currentPage]);

  useEffect(() => {
    fetchActiveMiners().then((data) => {
      setActiveMiners(data.activeMiners);
    });
    fetchTotalPoolHashrate().then((data) => {
      const formattedHashrate = formatHashrate(data.totalPoolHashrate);
      setPoolHashrate(formattedHashrate);
    });
    fetchBlocksPerHour(1).then((data) => {
      // '1' is the hour
      console.log(data)
      setBlocksPerHour(data?.blockCount || 0);
    });
    handleFetchBlocks();
    getHashrateHistory().then((data) => {
      setHashrateData(data);
    });
  }, [handleFetchBlocks]);

  
  const generateTimeLabels = (start, end, interval) => {
    const labels = [];
    const currentDate = new Date(start.getTime()); 

    while (currentDate <= end) {
      const minutes = Math.floor(currentDate.getMinutes() / interval) * interval;
      labels.push(`${currentDate.getDate()}/${currentDate.getMonth() + 1} ${currentDate.getHours()}:${minutes < 10 ? "0" : ""}${minutes}`);
      currentDate.setMinutes(currentDate.getMinutes() + interval); 
    }

    return labels;
  };

  const labels = useMemo(() => {
    const endDate = new Date();
    const startDate = new Date(endDate.getTime() - 24 * 60 * 60 * 1000); // Poslednja 24 sata
    return generateTimeLabels(startDate, endDate, 10); // Interval od 10 minuta
  }, []);

  const sortedGroupedData = useMemo(() => hashrateData.map((item) => ({
    x: `${new Date(item.time * 1000).toLocaleTimeString()}`,  
    y: item.hashrate
  })), [hashrateData]);

  const chartData = {
    labels,
    datasets: [
      {
        label: "Hashrate",
        data: sortedGroupedData.map(item => item.y),
        borderColor: "rgba(159, 211, 252, 1)",
        backgroundColor: "rgba(208, 129, 247, 0.5)",
        fill: false,
        tension: 0.4,
        //borderWidth: 2
      },
    ],
  };

  return (
    <div className="home">
      <div className="header-content">
        <div className="title"></div>
        <button className="--common-btn" onClick={() => setIsModalOpen(true)}>Join</button>
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h2>Join Our Pool</h2>
        <p>To join our pool, please use the following parameters:</p>
        <p><strong>Recommended miner:</strong> T-REX 0.26.8</p>
        <h3>Settings for Miner</h3>
        <pre>{minerSettings}</pre>
        <button className="--common-btn" onClick={copyToClipboard}>Copy Settings</button>
        {copySuccess && <p className="copy-success">{copySuccess}</p>}
        <p className="red-color"><strong>Notice:</strong> Please use the correct address starting with "ey..."</p>
      </Modal>
      </div>
      <div className="info-boxes">
        <InfoBox icon={icon} label="Pool Hashrate" value={poolHashrate || 0} />
        <InfoBox icon={icon} label="Blocks per Hour" value={blocksPerHour || 0} />
        <InfoBox icon={icon} label="Online Miners" value={activeMiners || 0} />
        <InfoBox icon={icon} label="Pool Luck" value="-" />
      </div>
      <div className="chart-area">
        <div className="chart-title">Pool Chart</div>
        <Line data={chartData} options={options} />
      </div>
      <div className="blocks-table">
        <div className="table-section">
          <div className="cell workers">Date</div>
          <div className="cell workers">Height</div>
          <div className="cell workers">Block Hash</div>
          <div className="cell workers"></div>
          <div className="cell workers">Confirmed</div>
        </div>
        {blocks.map((block, index) => (
          <div className="table-section" key={index}>
            <div className="cell rewards">{block.timestamp}</div>
            <div className="cell rewards">{block.height}</div>
            <div className="cell rewards">{block.hash}</div>
            <div className="cell rewards"></div>
            <div className="cell rewards">
              {block.orphaned === "1"
                ? "Orphaned"
                : block.confirmed === "1"
                ? "Yes"
                : "No"}
            </div>
          </div>
        ))}
        <div className="pagination-buttons">
          <button className="--common-btn" onClick={handlePrevPage}>
            Prev
          </button>
          <div className="current-page"> {currentPage}</div>
          <button className="--common-btn" onClick={handleNextPage}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
};
