import React from 'react';
import './Footer.css';

function Footer() {
    return (
        <footer className="footer">
            <div className="social-links">
                <h2>Social Links</h2>
                <p>Discord | Twitter | Telegram</p>
            </div>
            <div className="legal">
                <h2>Legal</h2>
                <p>Terms and Conditions | Privacy Policy</p>
            </div>
            <hr />
            <p className="copyright">
                © 2024 Eiyaro all rights reserved built by @wlink_net
            </p>
        </footer>
    );
}

export default Footer;
