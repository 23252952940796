import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Header.css';
import logo from '../../icons/logo.png';

function Header() {
    const [searchInput, setSearchInput] = useState('');
    const navigate = useNavigate();

    const handleSearchChange = (event) => {
        setSearchInput(event.target.value);
    };

    const handleSearchSubmit = (event) => {
        event.preventDefault();
        if (searchInput.trim()) {
            navigate(`/stats/${searchInput.trim()}`);
        }
    };

    return (
        <header className="header">
            <div className="logo-and-title">
                <img src={logo} alt="EIYARO POOL Logo" className="logo" />
                <span className="title">EIYARO POOL</span>
            </div>
            <div className="search-bar">
                <form onSubmit={handleSearchSubmit}>
                    <input
                        type="text"
                        placeholder="Search..."
                        value={searchInput}
                        onChange={handleSearchChange}
                    />
                </form>
            </div>
            <div className="navigation">
                <a href="/">Home</a>
                <a href="/">Workers</a>
                <a href="/">Status</a>
            </div>
        </header>
    );
}

export default Header;
